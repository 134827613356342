/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Global Window DOM object
    var $window         = $(window),
        $body           = $('body'),
        $fadeItem       = $(".fade-in-item");
        $overflowTrans  = $(".overflow-trans"),
        $fadeTrans      = $(".fade-transition"),
        defaultLoader   = '<i class="fa default-loader fa-circle-o-notch fa-spin fa-3x fa-fw"></i>';

  // Smooth scrolling @ref https://css-tricks.com/snippets/jquery/smooth-scrolling/
    $body.on('click', 'a[href*="#"]:not([href="#"])', function (e) {

      e.preventDefault();

      if (!$(this).hasClass('pill-link')){
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      }
    });

  // In view scroll

    function elementInView(){
      $fadeItem.each(function() {
        new Waypoint.Inview({
          element: this,
          enter: function(direction) {
            $(this.element).addClass('in-viewport');
          }
        });
      });

      $overflowTrans.each(function() {
        new Waypoint.Inview({
          element: this,
          enter: function(direction) {
            $(this.element).addClass('in-viewport');
          }
        });
      });

      $fadeTrans.each(function() {
        new Waypoint.Inview({
          element: this,
          enter: function(direction) {
            $(this.element).addClass('in-viewport');
          }
        });
      });
    }

    $window.load( function(){
      elementInView();

    });

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var flexsliderDefault = $('.flexslider-default'),
            $flexsliderRooms  = $('#slider-rooms'),
            $navToggle        = $('.nav-toggle'),
            $bookingsToggle   = $('.bookings-menu-toggle'),
            $closeBookings    = $('.close-bookings'),
            $closeNav         = $('.close-nav-menu'),
            $bookings         = $('.bookings'),
            $bookingHome      = $('#booking-home'),
            $bookingRoom      = $('#booking-room'),
            $bookingRest      = $('#booking-rest'),
            $videoBlock       = $('.video-block'),
            $videoToggle      = $('.video-toggle'),
            $galleryToggle    = $('.gallery-toggle'),
            $galleryClose     = $('.close-gallery'),
            $galleryContainer = $('.show-hide-slider'),
            $selectionLink    = $('.selection-link'),
            $closeSelection   = $('.close-barn-hall'),
            $panoWrapBg       = $('.pano-wrap .bg-cover'),
            $hallBarnCont     = $('#hall-barn-container'),
            $hsPanel          = $('.hide-show-panel'),
            $hallImage        = $('.hide-show-panel.hall'),
            $barnImage        = $('.hide-show-panel.barn'),
            timer,
            timer2;

        // Home hall/barn selection hover function

        $selectionLink.hover(function() {

            if($(this).hasClass('hall')){
              timer = setTimeout(function() {
                $panoWrapBg.removeClass('right-axis');
                $panoWrapBg.addClass('left-axis');
              }, 1000);

            } else {

              timer = setTimeout(function() {
                $panoWrapBg.removeClass('left-axis');
                $panoWrapBg.addClass('right-axis');
              }, 1000);
            }

          clearTimeout(timer2);
        });

        $selectionLink.mouseout(function(){
          // on mouse out, start a timeout
            timer2 = setTimeout(function() {
              $panoWrapBg.removeClass('left-axis right-axis');
            }, 2000);

            clearTimeout(timer);
        });

        // Home hall/barn selection click function
          $selectionLink.click(function (e) {
            e.preventDefault();
            $hallBarnCont.addClass('active');

            setTimeout(function() {
              $panoWrapBg.removeClass('left-axis right-axis');
            }, 1200);

            if($(this).hasClass('hall')){
              $hallImage.show();
            } else if($(this).hasClass('barn')) {
              $barnImage.show();
            }
          });

          // Home hall/barn close selection click function
            $closeSelection.click(function (e) {
              e.preventDefault();
              $hallBarnCont.removeClass('active');

              setTimeout(function(){
                $hsPanel.hide();
              }, 1000);
            });

        // Search toggle click function
          $navToggle.click(function (e) {
            e.preventDefault();
            $body.toggleClass('nav-menu-open');
          });

          // Search toggle click function
          $bookingsToggle.click(function (e) {
            e.preventDefault();
            $body.toggleClass('bookings-menu-open');
          });

          $closeBookings.click(function (e) {
            e.preventDefault();
            $body.removeClass('bookings-menu-open');

            $('.booking-options').fadeOut( "slow", function() {
              $bookingHome.delay(1000).fadeIn();
            });
          });

          $closeNav.click(function (e) {
            e.preventDefault();
            $body.removeClass('nav-menu-open');
          });

          // Bookings menu button functionality
          $bookings.click(function (e) {
            e.preventDefault();

            if($(this).hasClass('room-toggle')){
              $bookingHome.fadeOut( "slow", function() {
                $bookingRoom.fadeIn();
              });
            } else if($(this).hasClass('rest-toggle')) {
              $bookingHome.fadeOut( "slow", function() {
                $bookingRest.fadeIn();
              });
            } else {
              $('.booking-options').fadeOut( "slow", function() {
                $bookingHome.delay(1000).fadeIn();
              });
            }
          });


        if (flexsliderDefault.length) {
            var customNav = $('.flexslider-custom-nav-page');

            $('.flexslider').flexslider({
                animation: 'fade',
                controlNav: false,
                directionNav: false,
                prevText: '',
                nextText: '',
                slideshow: false,
                before: function(slider){
                  if (customNav.length) {
                    customNav.text(1 + slider.animatingTo);
                  }
                },
                start: function(slider){
                  if (customNav.length) {
                    customNav.text(1 + slider.animatingTo);
                  }
                  $('.custom-nav a.flex-prev').click(function(event){
                      event.preventDefault();
                      slider.flexAnimate(slider.getTarget("prev"));
                  });
                  $('.custom-nav a.flex-next').click(function(event){
                      event.preventDefault();
                      slider.flexAnimate(slider.getTarget("next"));
                  });
                },
            });
        }

        // Initialise top home Flexslider

          function flexsliderRooms(){

            if ($('#slider-rooms').length){
              var customRoomNav = $('.flexslider-custom-nav-page');

              $('#slider-rooms').flexslider({
                animation: "fade",
                directionNav: false,
                controlNav: true,
                slideshowSpeed: 6000,
                pauseOnAction: false,
                pauseOnHover: false,
                video: false,
                prevText: "",
                nextText: "",
                slideshow: false,
                before: function(slider){
                  if (customRoomNav.length) {
                    customRoomNav.text(1 + slider.animatingTo);
                  }
                },
                start: function(slider){
                  if (customRoomNav.length) {
                    customRoomNav.text(1 + slider.animatingTo);
                  }
                  $('.custom-nav a.flex-prev').click(function(event){
                      event.preventDefault();
                      slider.flexAnimate(slider.getTarget("prev"));
                  });
                  $('.custom-nav a.flex-next').click(function(event){
                      event.preventDefault();
                      slider.flexAnimate(slider.getTarget("next"));
                  });
                  slideItem = slider.slides.eq(0);
                  slideItem.addClass('animating');
                },
                end: function(slider){
                  slider.flexAnimate(0);
                },
                after: function(slider){
                  $('#slider-rooms .slides li').removeClass('animating');
                  slideItem = slider.slides.eq(slider.currentSlide);
                  slideItem.addClass('animating');
                }
              });

              $('#rooms-slide-block').fadeTo( 500 , 1, function() {
                $('.default-loader').remove();
              });
            }

          };

          $window.load( function(){
            flexsliderRooms();
          });

         // Video player play function

          $videoToggle.click(function(e){
            // Remove default action
            e.preventDefault();

            var $videoId = $(this).data('id'),
                $currentVideo = $('video#'+ $videoId).get(0),
                $playContainer = $('.display-table[data-id="'+ $videoId +'"]');

            if ($currentVideo.paused == false) {
                $currentVideo.pause();
                $playContainer.removeClass('fade-out');
                $('.video-toggle .fa').removeClass('fa-pause').addClass('fa-play');
            } else {
                $currentVideo.play();
                $playContainer.addClass('fade-out');
                $('.video-toggle .fa').removeClass('fa-play').addClass('fa-pause');
            }

            $currentVideo.onended = function(e) {
              $playContainer.removeClass('fade-out');
              $('.video-toggle .fa').removeClass('fa-pause').addClass('fa-play');
            };

          });

          // Room gallery show
          $galleryToggle.click(function(e){
            // Remove default action
            e.preventDefault();

            $galleryContainer.addClass('show-slider');
          });

           // Room gallery show
          $galleryClose.click(function(e){
            // Remove default action
            e.preventDefault();

            $galleryContainer.removeClass('show-slider');
          });

          // Ajax load room
            function updatePageContent(roomId) {
              $.ajax({
                url: MyAjax.ajaxurl,
                type: 'POST',
                cache: false,
                data: {
                  'action': 'load_next_room',
                  'room_id': roomId
                },
                dataType: 'html',
                success: function (data) {
                  $('#rooms-slide-block').html('').append(data);
                },
                complete: function () {
                  flexsliderRooms();
                },
                error: function (errorThrown) {
                  console.log(errorThrown);
                }
              });
            }

            $body.on('click', '.right-arrow', function(e) {
                // Remove default action
                e.preventDefault();

                if($('#current-room').data("id") <= 5) {
                    var nextId;
                    if($('#current-room').hasClass('intro')) {
                      nextId = 0;
                    } else {
                      nextId = $('#current-room').data("id") + 1;
                    }

                    $('.gallery-wrap').append(defaultLoader);

                    $('#rooms-slide-block').fadeTo( 500 , 0.5, function() {
                      updatePageContent(nextId);
                    });
                }
            });

            $body.on('click', '.left-arrow', function(e) {
                // Remove default action
                e.preventDefault();

                if($('#current-room').data("id") > 0) {
                    var prevId;
                    if($('#current-room').hasClass('end')) {
                      prevId = 0;
                    } else {
                      prevId = $('#current-room').data("id") - 1;
                    }
                    $('.gallery-wrap').append(defaultLoader);

                    $('#rooms-slide-block').fadeTo( 500 , 0.5, function() {
                      updatePageContent(prevId);
                    });
                }
            });

            // Stop multiple submissions on form
            // $(".wpcf7").on('wpcf7:submit', function(event){
            //   $('input.wpcf7-submit').attr('disabled','disabled');
            // });
            $body.on('click', 'input.wpcf7-submit', function() {
              $(this).fadeOut();
            });

            $('#videoModal').on('hidden.bs.modal', function () {
                var $frame = $('#videoModal iframe');
                var vidsrc = $frame.attr('src');
                $frame.attr('src','');
                $frame.attr('src', vidsrc);
            })
      }
    },
    // Category page
    'category': {
      init: function () {
        // Declare vars
        var $posts = $('#listed-posts'),
            $postsList = $('#posts-wrap'),
            $postsContainer = $('#posts-container'),
            $loadMorePosts = $('#load-more-posts');

        // Ajax load more Profiles
        function loadMorePosts(offset, term) {
          $.ajax({
            url: MyAjax.ajaxurl,
            type: 'post',
            dataType: 'html',
            data: {
              action: 'load_more_posts',
              offset: offset,
              category_term: term
            },
            success: function (data) {
              if (!data) {
                $postsList.append('<p class="no-posts text-center margin-top-md display-inline-block width-100">Sorry, there are no more posts to show.</p>');
                $('#load-more-posts').hide();
              } else {
                $postsList.append(data);
              }
              $postsList.fadeTo(500, 1);
            },
            error: function (xhr, ajaxOptions, thrownError) {
              console.error(ajaxOptions);
              console.error(thrownError);
            }
          });
        }

        $loadMorePosts.click(function (e) {
          e.preventDefault();

          var $offset = $('.post-item').length,
            $term_id = $('#term_id').attr('data-term');

          $postsList.fadeTo(500, 0.3, function () {
            loadMorePosts($offset, $term_id);
          });
        });
      }
    },
    // Flexible content template
    'page_template_template_flexible': {
      init: function() {
        // Declare variables
        var $scrollNav = $('.scroll-nav'),
            $scrollPoint = document.getElementById('scroll-point');

        //  Scroll sticky nav
        function scrollNav(){

          $windowWidth = $(window).width();

          if($windowWidth > 768) {

            if($scrollNav.length){

              var waypoint = new Waypoint({
                element: $scrollPoint,
                handler: function(direction) {
                  if (direction === 'up') {
                    $(this.element).removeClass('sticky');
                    $('.scroll-nav').removeClass('sticky');
                  } else if(direction === 'down') {
                    $(this.element).addClass('sticky');
                    $('.scroll-nav').addClass('sticky');
                  }
                },
              });

            }

          }

        }

        // Run on window resize
        $window.resize( function(){
          scrollNav();
        });

        // Run on document ready
        scrollNav();

      }
    },
    // Careers page
    'page_template_template_careers': {
      init: function() {
        // Vars
        var $jobSelect = $('#job-roles');

        // Job type on change
        $jobSelect.on('change', function() {
          var jobVal = $(this).val(),
              dataVal = $('.block-grid-item[data-id="'+ jobVal +'"]'),
              $jobItem = $('.block-grid-item'),
              $jobLisings = $('.job-listings');

              if( $(this).val() == 'all-roles') {
                $jobLisings.fadeTo(500, 0.3, function () {
                  $jobItem.show();
                  $jobLisings.fadeTo(500, 1);
                });
              } else {
                $jobLisings.fadeTo(500, 0.3, function () {
                  $jobItem.hide();
                  dataVal.show();
                  $jobLisings.fadeTo(500, 1);
                });
              }
        });
      }
    },
    // Careers page
    'single_careers': {
      init: function() {
        // Add job title on hidden form field

        var $career = $('#career-job-title').attr('data-career');
        $('#career-name').val($career);

      }
    },
    'page_template_template_contact': {
      init: function() {
        // JavaScript to be fired on the about us page
        /*
        *  This function will render a Google Map onto the selected jQuery element
        */

        function new_map( $el ) {

          // var
          var $markers = $el.find('.marker');


          // vars
          var args = {
            zoom    : 16,
            scrollwheel: false,
            center    : new google.maps.LatLng(0, 0),
            mapTypeId : google.maps.MapTypeId.ROADMAP,
            styles : [{"elementType":"geometry","stylers":[{"hue":"#E3E0D7"},{"saturation":0},{"lightness":0},{"gamma":0}]},{"featureType":"road","elementType":"labels.icon"},{"featureType":"landscape.man_made","elementType":"geometry","stylers":[{"hue":"#0077ff"},{"gamma":3.1}]},{"featureType":"water","stylers":[{"hue":"#00ccff"},{"gamma":0.44},{"saturation":-33}]},{"featureType":"poi.park","stylers":[{"hue":"#44ff00"},{"saturation":-23}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"hue":"#007fff"},{"gamma":0.77},{"saturation":65},{"lightness":99}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"gamma":0.11},{"weight":5.6},{"saturation":99},{"hue":"#0091ff"},{"lightness":-86}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"lightness":-48},{"hue":"#ff5e00"},{"gamma":1.2},{"saturation":-23}]},{"featureType":"transit","elementType":"labels.text.stroke","stylers":[{"saturation":-64},{"hue":"#ff9100"},{"lightness":16},{"gamma":0.47},{"weight":2.7}]}]
          };


          // create map
          var map = new google.maps.Map( $el[0], args);


          // add a markers reference
          map.markers = [];


          // add markers
          $markers.each(function(){

              add_marker( $(this), map );

          });


          // center map
          center_map( map );


          // return
          return map;

        }

        /*
        *  This function will add a marker to the selected Google Map
        */

        function add_marker( $marker, map ) {

          // var
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') ),
              contentString = '<h5 class="text-center">Moor Hall Restaurant, Prescot Road, Aughton, Lancashire, L39 6RT</h5>';

          var infowindow = new google.maps.InfoWindow({
              content: contentString
          });

          // create marker
          var marker = new google.maps.Marker({
            position  : latlng,
            map     : map,
            icon: 'https://s3-eu-west-1.amazonaws.com/moor-hall/wp-content/uploads/2016/11/02094050/marker-circle.png'
          });

          marker.addListener('click', function() {
            infowindow.open(map, marker);
          });

          // add to array
          map.markers.push( marker );

          // if marker contains HTML, add it to an infoWindow
          if( $marker.html() )
          {
            // create info window
            var infowindow = new google.maps.InfoWindow({
              content   : $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {

              infowindow.open( map, marker );

            });
          }

        }

        /*
        *  This function will center the map, showing all markers attached to this map
        */

        function center_map( map ) {

          // vars
          var bounds = new google.maps.LatLngBounds();

          // loop through all markers and create bounds
          $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

            bounds.extend( latlng );

          });

          // only 1 marker?
          if( map.markers.length == 1 )
          {
            // set center of map
              map.setCenter( bounds.getCenter() );
              map.setZoom( 16 );
          }
          else
          {
            // fit to bounds
            map.fitBounds( bounds );
          }

        }

        // global var
        var map = null;

        $(document).ready(function(){

          $('.acf-map').each(function(){

            // create map
            map = new_map( $(this) );

          });

        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Loads home functions on another page
    //Sage.blog = {
      //init: Sage.home.init
    //};

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
